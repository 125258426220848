import React from "react";
import { Helmet, HelmetProvider } from "react-helmet-async";
import Boxed from "./Boxed";
import BackBtn from "../Components/BackBtn";

const Video = () => {
  return (
    <>
      <HelmetProvider>
        <Helmet>
          <title>ZeroTech Video Resources</title>
          <meta
            name="description"
            content="Zerotech Resources - The place to find ZeroTech video resources."
          />
          <meta
            name="keywords"
            content="ZeroTech, videos, video, riflescopes, resources"
          />
        </Helmet>
      </HelmetProvider>
      <Boxed
        children={
          <>
            <BackBtn />
            <h1>VIDEO RESOURCSE</h1>
            <section>
              <article class="video_wrapper">
                <figure>
                  <iframe
                    title="CHOOSING THE RIGHT ZEROTECH OPTIC FOR YOUR RIFLE BUILD WITH JACK FROM VERIFIED LONG RANGE"
                    src="https://www.youtube.com/embed/Y0_laR3LrFk"
                    width="100%"
                    height="100%"
                    frameborder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowfullscreen
                  ></iframe>
                  <figcaption>
                    Choosing The Right Zerotech Optic For Your Rifle Build With
                    Jack From Verified Long Range
                  </figcaption>
                </figure>
              </article>
              <article class="video_wrapper">
                <figure>
                  <iframe
                    title="Rifle 'canting' explained with  ZeroTech Precision Optics field development team"
                    src="https://www.youtube.com/embed/jyH_iZvP4QE"
                    width="100%"
                    height="100%"
                    frameborder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowfullscreen
                  ></iframe>
                  <figcaption>
                    Rifle 'canting' explained with ZeroTech Precision Optics
                    field development team
                  </figcaption>
                </figure>
              </article>
            </section>
          </>
        }
      ></Boxed>
    </>
  );
};

export default Video;
