import React from "react";
import { NavLink } from "react-router-dom";

const Nav = () => {
  return (
    <>
      <ul className="nav">
        <li>
          <NavLink to="/">HOME</NavLink>
        </li>
        <li>
          <NavLink to="/contact">CONTACT</NavLink>
        </li>

        <li>
          REGION ▼
          <ul className="navSub">
            <li>
              <a
                href="https://zerotech.com.au"
                target="_blank"
                rel="noreferrer"
              >
                AUS
              </a>
            </li>
            <li>
              <a
                href="https://www.zerotechoptics.com"
                target="_blank"
                rel="noreferrer"
              >
                USA
              </a>
            </li>
            <li>
              <a
                href="https://zerotechoptics.co.uk"
                target="_blank"
                rel="noreferrer"
              >
                UK
              </a>
            </li>
          </ul>
        </li>
      </ul>
    </>
  );
};

export default Nav;
