import React, { useState } from "react";
import ProductDownloads from "../ProductDownloads";
import ProductImage from "./ProductImage";
const AccessoriesContent = ({ product, openPopup }) => {
  const [imageopen, setImageopen] = useState(false);

  return (
    <div className="productContentBox">
      <div className="productContentBoxInner bino">
        <div className="leftCol">
          <h2 className="open">
            {product.title} - {product.category.toUpperCase()}
          </h2>
          <p id="ExternalLink" onClick={openPopup}>
            View on ZeroTech Website
          </p>
          <article className="downloads">
            <ProductDownloads product={product} type="accessories" />
          </article>
        </div>

        <section className="rightCol">
          <article className="accessoriesPhoto">
            <ProductImage
              image={product.productImage}
              title={product.title}
              imageopen={imageopen}
              setImageopen={setImageopen}
            />
          </article>
        </section>
      </div>
    </div>
  );
};

export default AccessoriesContent;
