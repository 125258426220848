import React, { useState } from "react";
import ProductDownloads from "../ProductDownloads";
import ReticleImg from "../ReticleImg";
import DimensionsImg from "../DimensionsImg";
const RiflescopeContent = ({ product, openPopup }) => {
  const [dimensionsOpen, setDimensionsOpen] = useState(false);
  const [reticleOpen, setReticleOpen] = useState(false);

  return (
    <div className="productContentBox">
      <div className="productContentBoxInner">
        <div className="productheading">
          <h2 className="open">
            {product.title} - {product.category.toUpperCase()}
          </h2>
          <p id="ExternalLink" onClick={openPopup}>
            View on ZeroTech Website
          </p>
        </div>
        <section className="productDetails">
          <article className="downloads">
            <ProductDownloads product={product} type="riflescope" />
          </article>
          <article className="reticle">
            <ReticleImg
              product={product.reticle}
              setReticleOpen={setReticleOpen}
              reticleOpen={reticleOpen}
            />
          </article>
          <article className="dimensions">
            <DimensionsImg
              product={product.dimensionsUrl}
              dimensionsOpen={dimensionsOpen}
              setDimensionsOpen={setDimensionsOpen}
            />
          </article>
        </section>
      </div>
    </div>
  );
};

export default RiflescopeContent;
