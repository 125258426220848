import React from "react";
import Modal from "../../Modal";

const ProductImage = ({ image, title, setImageopen, imageopen }) => {
  return (
    <>
      <img src={image} alt={title} onClick={() => setImageopen(true)} />

      <Modal open={imageopen} onClose={() => setImageopen(false)}>
        <img src={image} alt={title} />
      </Modal>
    </>
  );
};

export default ProductImage;
