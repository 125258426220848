import React from "react";
import Modal from "../Modal";

const ReticleImg = ({ product, reticleOpen, setReticleOpen }) => {
  let blackReticleUrl = "";
  let whiteReticleUrl = "";

  switch (product) {
    case "rar-illum":
      blackReticleUrl = "/images/reticles/rar-illum.png";
      whiteReticleUrl = "/images/reticles/rar-illum-white.png";
      break;
    case "zeroplex":
      blackReticleUrl = "/images/reticles/zeroplex.png";
      whiteReticleUrl = "/images/reticles/zeroplex-white.png";
      break;
    case "phr":
      blackReticleUrl = "/images/reticles/phr.png";
      whiteReticleUrl = "/images/reticles/phr-white.png";
      break;
    case "phrii":
      blackReticleUrl = "/images/reticles/phrii.png";
      whiteReticleUrl = "/images/reticles/phrii-white.png";
      break;
    case "phrii-illum":
      blackReticleUrl = "/images/reticles/phrii-illum.png";
      whiteReticleUrl = "/images/reticles/phrii-illum-white.png";
      break;
    case "r3":
      blackReticleUrl = "/images/reticles/r3.png";
      whiteReticleUrl = "/images/reticles/r3-white.png";
      break;
    case "r3-illum":
      blackReticleUrl = "/images/reticles/r3-illum.png";
      whiteReticleUrl = "/images/reticles/r3-illum-white.png";
      break;
    case "phr3":
      blackReticleUrl = "/images/reticles/phr3.png";
      whiteReticleUrl = "/images/reticles/phr3-white.png";
      break;
    case "mildot":
      blackReticleUrl = "/images/reticles/mildot.png";
      whiteReticleUrl = "/images/reticles/mildot-white.png";
      break;
    case "dot":
      blackReticleUrl = "/images/reticles/dot.png";
      whiteReticleUrl = "/images/reticles/dot-white.png";
      break;
    case "multi-dot":
      blackReticleUrl = "/images/reticles/Red-Dot-Multi-Reticle.png";
      whiteReticleUrl = "/images/reticles/Red-Dot-Multi-Reticle-white.png";
      break;
    case "green-dot":
      blackReticleUrl = "/images/reticles/Red-Dot-green-dot.png";
      whiteReticleUrl = "/images/reticles/Red-Dot-green-dot-white.png";
      break;
    case "hunter":
      blackReticleUrl = "/images/reticles/hunter.png";
      whiteReticleUrl = "/images/reticles/hunter-white.png";
      break;
    case "hunter-illum":
      blackReticleUrl = "/images/reticles/hunter-illum.png";
      whiteReticleUrl = "/images/reticles/hunter-illum-white.png";
      break;
    case "rmg":
      blackReticleUrl = "/images/reticles/rmg.png";
      whiteReticleUrl = "/images/reticles/rmg-white.png";
      break;
    case "rmg2-illum":
      blackReticleUrl = "/images/reticles/rmg2-illum.png";
      whiteReticleUrl = "/images/reticles/rmg2-illum-white.png";
      break;
    case "rmg-moa":
      blackReticleUrl = "/images/reticles/rmg-moa.png";
      whiteReticleUrl = "/images/reticles/rmg-moa-white.png";
      break;
    case "tremor3":
      blackReticleUrl = "/images/reticles/tremor3.png";
      whiteReticleUrl = "/images/reticles/tremor3-white.png";
      break;
    case "osr":
      blackReticleUrl = "/images/reticles/osr.png";
      whiteReticleUrl = "/images/reticles/osr-white.png";
      break;
    default:
      blackReticleUrl = "";
  }

  return (
    <>
      <h3>Reticle</h3>
      {blackReticleUrl ? (
        <>
          <img
            src={whiteReticleUrl}
            alt="reticle"
            onClick={() => setReticleOpen(true)}
          />
          <Modal open={reticleOpen} onClose={() => setReticleOpen(false)}>
            <img src={blackReticleUrl} alt="Large Reticle" />
          </Modal>
        </>
      ) : (
        "No Image Available"
      )}
    </>
  );
};

export default ReticleImg;
