import React from "react";
import { useEffect } from "react";
import Boxed from "./Boxed";
const Contact = () => {
  useEffect(() => {
    const form = document.getElementById("form");
    form.addEventListener("submit", formSubmit);
  }, []);

  function formSubmit(e) {
    e.preventDefault();

    const formData = new FormData();
    formData.append("name", document.querySelector('input[name="name"]').value);
    formData.append(
      "email",
      document.querySelector('input[name="email"]').value
    );
    formData.append("message", document.querySelector("textarea").value);

    fetch("https://getform.io/f/d64fc435-e7ea-413e-9392-e6e433d7a4ac", {
      method: "POST",
      body: formData,
    })
      .then((response) => {
        let successMessage = document.getElementById("response");
        successMessage.innerText = "Message Sent";
        successMessage.classList.add("success");
      })
      .catch((error) => {
        let errorMessage = document.getElementById("response");
        errorMessage.innerText = "There was an error. Please try again later";
        errorMessage.classList.add("error");
      });
  }

  return (
    <>
      <section id="bannerImg"></section>
      <Boxed>
        <h1>Contact Zerotech</h1>
        <form id="form" action="">
          <input type="text" name="name" placeholder="Name" />
          <input type="email" name="email" placeholder="Email" />
          <textarea
            type="textarea"
            name="message"
            rows="15"
            placeholder="Your Message"
          />

          <button type="submit">
            <span>SEND</span>
          </button>
        </form>
        <p id="response"></p>
      </Boxed>
    </>
  );
};

export default Contact;
