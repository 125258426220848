import React from "react";
import Data from "../data/resources.json";
import { NavLink } from "react-router-dom";

const Resourceloop = ({ setProduct, selectProduct, product, productType }) => {
  let categories = Data.categories;
  let currentCat = categories.filter(
    (thiscat) => thiscat.category === productType
  );

  return (
    <div className="catContainer">
      {currentCat.map((category) => (
        <div key={category.title} className="singleCat">
          <NavLink to={category.catUrl}>
            <img src={category.imageUrl} alt={category.title} />
          </NavLink>
        </div>
      ))}
    </div>
  );
};

export default Resourceloop;
