import React from "react";
import Data from "../../../../data/resources.json";
import { NavLink, useParams } from "react-router-dom";
import BackBtn from "../../../BackBtn";
import Boxed from "../../../../pages/Boxed";
import { useEffect } from "react";
import ProductContent from "../../ProductContent";
import { Helmet, HelmetProvider } from "react-helmet-async";

const Sunshade = ({ product, setProduct, selectProduct }) => {
    let categories = Data.products;
    let sunshadeAccessories = categories
        .filter((thiscat) => thiscat.category === "sunshade")
        .filter((thisCatScopes) => thisCatScopes.type === "accessories");

    const location = useParams();

    useEffect(() => {
        const currentUrl = Object.values(location);
        if (currentUrl[0] !== "") {
            let thisUrl = sunshadeAccessories.filter((currentCat) =>
                currentCat.url.includes(currentUrl[0])
            );
            setProduct(thisUrl[0]);
        } else {
            let firstProduct = sunshadeAccessories[0];
            setProduct(firstProduct);
        }
    });

    function resetButton(clicked) {
        let resetAll = document.querySelectorAll(".singleProduct a");

        for (let i = 0; i < resetAll.length; i++) {
            resetAll[i].style.backgroundColor = "black";
        }
        clicked.target.style.backgroundColor = "var(--orange)";
        clicked.target.style.borderColor = "var(--orange)";
    }

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    return (
        <>
            <HelmetProvider>
                <Helmet>
                    <title>ZeroTech Accessories Sunshade</title>
                    <meta
                        name="description"
                        content="Zerotech Resources - The one-stop shop for all ZeroTech Accessories Sunshade resources."
                    />
                    <meta
                        name="keywords"
                        content="ZeroTech, accessories, sunshade, resources, manuals"
                    />
                </Helmet>
            </HelmetProvider>
            <Boxed
                children={
                    <>
                        <BackBtn />
                        <h1>Sunshade 50"</h1>
                        <p>
                            The ZeroTech 3″ Sunshade threads directly into the objective of your Zerotech 50mm riflescope. A sunshade can greatly reduce glare experienced by the shooter, especially when the sun is low on the horizon.
                        </p>
                        <p>Fits the following models:</p>
                        <table>
                            <tr>
                                <th>Category</th>
                                <th>Model</th>
                                <th>Detail</th>
                            </tr>
                            <tr>
                                <td>THRIVE HD</td>
                                <td>2.5-15x50mm</td>
                                <td>PHRII</td>
                            </tr>
                            <tr>
                                <td>THRIVE HD</td>
                                <td>2.5-15x50mm Illuminated</td>
                                <td>PHRII</td>
                            </tr>
                            <tr>
                                <td>THRIVE HD</td>
                                <td>6-24x50mm</td>
                                <td>PHRII</td>
                            </tr>
                            <tr>
                                <td>THRIVE HD</td>
                                <td>6-24x50mm Illuminated</td>
                                <td>PHRII</td>
                            </tr>
                            <tr>
                                <td>TRACE</td>
                                <td>3-18x50mm</td>
                                <td>R3</td>
                            </tr>
                            <tr>
                                <td>TRACE</td>
                                <td>4.5-27x50mm</td>
                                <td>R3</td>
                            </tr>
                            <tr>
                                <td>TRACE ADVANCED</td>
                                <td>4.5-27x50mm</td>
                                <td>RMG</td>
                            </tr>
                        </table>
                    </>
                }
            ></Boxed>
            <section className="productTabs condensed">
                {sunshadeAccessories.map((productList) => (
                    <div key={productList.title} className="singleProduct">
                        <NavLink
                            to={productList.url}
                            onClick={(e) => {
                                selectProduct(e, productList);

                                resetButton(e);
                            }}
                        >
                            {productList.title}
                        </NavLink>
                    </div>
                ))}
            </section>
            <ProductContent product={product} Layout="accessories" />
        </>
    );
};

export default Sunshade;
