import React from "react";
import Resourceloop from "../Components/Resourceloop";
import Boxed from "./Boxed";
const RedDots = ({ setProduct, selectProduct, product }) => {
  return (
    <>
      <Boxed Inner="inner">
        <h2>RED DOTS - REFLEX - PRISM SIGHTS</h2>
      </Boxed>
      <Resourceloop
        setProduct={setProduct}
        selectProduct={selectProduct}
        product={product}
        productType="reddots"
      />
    </>
  );
};

export default RedDots;
