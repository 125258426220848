import React from "react";
import Nav from "./Nav";

const HeaderRight = () => {
  return (
    <div className="headerRight">
      <Nav />
    </div>
  );
};

export default HeaderRight;
