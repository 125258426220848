import React from "react";
import Logo from "../../assets/ZeroTech-logo-small-white.webp";
import { NavLink } from "react-router-dom";
const HeaderLeft = () => {
  return (
    <div className="footerLeft">
      <NavLink to="/">
        <img src={Logo} alt="Zerotech Resources" />
      </NavLink>

      <h2 className="white">RESOURCES</h2>
    </div>
  );
};

export default HeaderLeft;
