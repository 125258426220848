import React from "react";
import Data from "../../../../data/resources.json";
import { NavLink, useParams } from "react-router-dom";
import BackBtn from "../../../BackBtn";
import Boxed from "../../../../pages/Boxed";
import { useEffect } from "react";
import ProductContent from "../BinocularContent";
import { Helmet, HelmetProvider } from "react-helmet-async";

const ThriveHdBino = ({ product, setProduct, selectProduct }) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  let categories = Data.products;
  let thriveHdBinoculars = categories
    .filter((thiscat) => thiscat.category === "thrive hd")
    .filter((thisCatScopes) => thisCatScopes.type === "binocular");

  const location = useParams();

  useEffect(() => {
    const currentUrl = Object.values(location);
    if (currentUrl[0] !== "") {
      let thisUrl = thriveHdBinoculars.filter((currentCat) =>
        currentCat.url.includes(currentUrl[0])
      );
      setProduct(thisUrl[0]);
    } else {
      let firstProduct = thriveHdBinoculars[0];
      setProduct(firstProduct);
    }
  });

  function resetButton(clicked) {
    let resetAll = document.querySelectorAll(".singleProduct a");

    for (let i = 0; i < resetAll.length; i++) {
      resetAll[i].style.backgroundColor = "black";
    }
    clicked.target.style.backgroundColor = "var(--orange)";
    clicked.target.style.borderColor = "var(--orange)";
  }

  return (
    <>
      <HelmetProvider>
        <Helmet>
          <title>ZeroTech Thrive HD Binoculars</title>
          <meta
            name="description"
            content="Zerotech Resources - The one-stop shop for all ZeroTech Thrive HD Binocular resources."
          />
          <meta
            name="keywords"
            content="ZeroTech, thrive hd, binoculars, resources, manuals, reticles, range chart,"
          />
        </Helmet>
      </HelmetProvider>
      <Boxed
        children={
          <>
            <BackBtn />
            <h1>Thrive HD Binoculars</h1>
            <p>
              Backed by our Triple A lifetime warranty ZeroTech products are
              engineered to provide our users with the best possible experience.
            </p>

            <p>
              The 10×25 Thrive HD Binoculars were designed in Australia to
              withstand the harsh and rapidly changing environments that the
              country is known for, Thrive HD optics have been tested from the
              tropical north, through the harsh deserts to the brutal southern
              highlands by some of the worlds most accomplished hunters and
              outdoors enthusiasts. Thrive HD Optics are built to weather all
              conditions and be the ideal companion for your next adventure.
            </p>

            <p>
              THRIVE HD Optics are continuing to set new industry standards in
              performance, made to compete against the top performing binoculars
              at a fraction of the cost. We took the Thrive series core elements
              and introduced new, industry leading features such as the latest
              generation ED (Low Dispersion) glass with generous depth of field,
              superior low Light performance thanks to the Dielectrically Coated
              Bak 4 Prisms.
            </p>

            <p>
              The addition of these features results in brilliant image quality
              and unparalleled user experience, we added a smoother fast focus
              wheel and attractive textured grip panels covering the strong
              however light full magnesium alloy construction provide excellent
              control in all conditions whilst the rubber lens covers ensure the
              optics remain protected from dust and debris. The 10×25 Thrive HD
              Binoculars are compact, making for the perfect pocket companion
              while hunting, birding or hiking.
            </p>
          </>
        }
      ></Boxed>
      <section className="productTabs">
        {thriveHdBinoculars.map((productList) => (
          <div key={productList.title} className="singleProduct">
            <NavLink
              to={productList.url}
              onClick={(e) => {
                selectProduct(e, productList);

                resetButton(e);
              }}
            >
              {productList.title}
            </NavLink>
          </div>
        ))}
      </section>
      <ProductContent product={product} Layout="binocular" />
    </>
  );
};

export default ThriveHdBino;
