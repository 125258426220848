import React from "react";
import Resourceloop from "../Components/Resourceloop";
import Boxed from "./Boxed";
const Accessories = ({ setProduct, selectProduct, product }) => {
  return (
    <>
      <Boxed Inner="inner">
        <h2>Accessories</h2>
      </Boxed>
      <Resourceloop
        setProduct={setProduct}
        selectProduct={selectProduct}
        product={product}
        productType="sunshade"
      />
    </>
  );
};

export default Accessories;
