// import React, { Children } from "react";

const Boxed = ({ children, Inner }) => {
  if (Inner) {
    return <div className="InnerContentBoxed">{children}</div>;
  } else {
    return <div className="mainContentInner">{children}</div>;
  }
};

export default Boxed;
