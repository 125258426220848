import React from "react";
import Resourceloop from "../Components/Resourceloop";
import Boxed from "./Boxed";

const Binoculars = ({ setProduct, selectProduct, product }) => {
  return (
    <>
      <Boxed Inner="inner">
        <h2>Binoculars</h2>
      </Boxed>
      <Resourceloop
        setProduct={setProduct}
        selectProduct={selectProduct}
        product={product}
        productType="binoculars"
      />
    </>
  );
};

export default Binoculars;
