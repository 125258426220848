import React from "react";
import Riflescopes from "./Riflescopes";
import RedDots from "./RedDots";
import Binoculars from "./Binoculars";
import SpottingScopes from "./SpottingScopes";
import Accessories from "./Accessories";
import Boxed from "./Boxed";
import { NavLink } from "react-router-dom";


const Home = () => {
  return (
    <Boxed
      children={
        <>
          <h1>ZeroTech Resources</h1>
          <p>
            Welcome to the Zerotech Resources website. Here you will find
            everything you need for your ZeroTech product.
          </p>
          <h2>Meet The ZeroTech Family</h2>
          <p>
            Imagine a world where your gear performed perfectly in all
            Australian conditions, where the reticles do the job they’re
            expected to do, the optics stand up to the dust and harsh conditions
            of this sunburnt country and you have 100% confidence that should
            your gear fail or break it will always be repaired or replaced, no
            matter the problem.
          </p>

          <section className="productListContainer">
            <Riflescopes />
          </section>
          <section className="productListContainer">
            <RedDots />
          </section>
          <section className="productListContainer">
            <Binoculars />
          </section>
          <section className="productListContainer">
            <SpottingScopes />
          </section>
          <section className="productListContainer">
            <Accessories />
          </section>
          <section className="productListContainer">
            <div className="InnerContentBoxed">
              <h2>VIDEO RESOURCES</h2>
            </div>
            <div className="catContainer">
              <div className="singleCat">
                <NavLink to="/videos">
                  <img src="/images/logos/riflescopes-logo.png" alt="" />
                </NavLink>
              </div>
            </div>
          </section>
          <section className="warranty">
            <article className="warrantyLogo">
              <img
                src="/images/logos/Warranty.webp"
                alt="ZeroTech Triple A Lifetime Warranty"
              />
            </article>
            <article className="warrantyText">
              <h2>Zerotech Triple A Lifetime Warranty</h2>
              <p>
                <strong>
                  ZeroTech products are covered for life, any owner, any
                  problem, always covered.
                </strong>
              </p>
              <p>
                With the backing and experience of over 50 years of optical
                instrument expertise we have developed the ZeroTech product
                range, a range of riflescopes designed and developed by shooters
                for shooters.
              </p>
            </article>
          </section>
          <section className="warning">
            <article className="warningText">
              <p>
                <strong>
                  WARNING – KEEP BATTERIES (whether the battery is new or used)
                  OUT OF REACH OF CHILDREN
                </strong>
              </p>
              <p>
                Zerotech rifle scopes and optics with a reticle illumination
                feature contain coin batteries. Products containing coin
                batteries must be kept out of reach of children. The battery can
                cause severe or fatal injuries in 2 hours or less if it is
                swallowed or placed inside any part of the body.
              </p>
              <p>
                Medical attention should be sought immediately if it is
                suspected the battery has been swallowed or placed in any part
                of the body.
              </p>
              <p>
                Call the 24-hour Poisons Information Centre on 13 11 26
                (Australia) for fast, expert advice.
              </p>
            </article>
            <article className="warningImg">
              <img
                src="/images/logos/button-safety-logo.webp"
                alt="Warning Label"
              />
            </article>
          </section>
        </>
      }
    ></Boxed>
  );
};

export default Home;
