import React from "react";
import HeaderLeft from "./HeaderLeft";
import HeaderRight from "./HeaderRight";

const Header = () => {
  return (
    <header>
      <div className="headerInner">
        <HeaderLeft />
        <HeaderRight />
      </div>
    </header>
  );
};

export default Header;
