import React from "react";
import Resourceloop from "../Components/Resourceloop";
import Boxed from "./Boxed";
const SpottingScopes = ({ setProduct, selectProduct, product }) => {
  return (
    <>
      <Boxed Inner="inner">
        <h2>Spotting Scopes</h2>
      </Boxed>
      <Resourceloop
        setProduct={setProduct}
        selectProduct={selectProduct}
        product={product}
        productType="spotting-scope"
      />
    </>
  );
};

export default SpottingScopes;
