import React from "react";
import { NavLink } from "react-router-dom";

const NotFound = () => {
  return (
    <div className="mainContentInner" style={{ textAlign: "center" }}>
      <h1 style={{ fontSize: "15em", lineHeight: "1em" }}>404</h1>
      <h2>Page Not Found</h2>
      <button className="backBtn">
        <NavLink to="/">BACK</NavLink>
      </button>
    </div>
  );
};

export default NotFound;
