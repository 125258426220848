import React from "react";
import CloseBtn from "./CloseBtn";
const Popup = ({ product }) => {
  return (
    <section id="popup">
      <CloseBtn close="popup" />
      <h3 className="popupTitle">SELECT YOUR REGION</h3>
      <div className="regionList">
        {product.ausUrl !== "" ? (
          <div>
            <a href={product.ausUrl} target="_blank" rel="noreferrer">
              <img src="/images/flags/aus-flag.jpg" alt="Australian Region" />
            </a>
          </div>
        ) : (
          ""
        )}
        {product.usUrl !== "" ? (
          <div>
            <a href={product.usUrl} target="_blank" rel="noreferrer">
              <img src="/images/flags/usa-flag.jpg" alt="USA Region" />
            </a>
          </div>
        ) : (
          ""
        )}
        {product.ukUrl !== "" ? (
          <div>
            <a href={product.ukUrl} target="_blank" rel="noreferrer">
              <img src="/images/flags/uk-flag.jpg" alt="Uk Region" />
            </a>
          </div>
        ) : (
          ""
        )}
      </div>
    </section>
  );
};

export default Popup;
