import React from "react";
import Data from "../../../../data/resources.json";
import { NavLink, useParams } from "react-router-dom";
import BackBtn from "../../../BackBtn";
import Boxed from "../../../../pages/Boxed";
import { useEffect } from "react";
import ProductContent from "../../ProductContent";
import { Helmet, HelmetProvider } from "react-helmet-async";

const TraceAdvanced = ({ product, setProduct, selectProduct }) => {
  let categories = Data.products;
  let traceAdvancedSpottingScopes = categories
    .filter((thiscat) => thiscat.category === "trace advanced")
    .filter((thiscat) => thiscat.type === "spotting-scope");

  const location = useParams();

  useEffect(() => {
    window.scrollTo(0, 0);
    const currentUrl = Object.values(location);
    if (currentUrl[0] !== "") {
      let thisUrl = traceAdvancedSpottingScopes.filter((currentCat) =>
        currentCat.url.includes(currentUrl[0])
      );
      setProduct(thisUrl[0]);
    } else {
      let firstProduct = traceAdvancedSpottingScopes[0];
      setProduct(firstProduct);
    }
  });

  function resetButton(clicked) {
    let resetAll = document.querySelectorAll(".singleProduct a");

    for (let i = 0; i < resetAll.length; i++) {
      resetAll[i].style.backgroundColor = "black";
    }
    clicked.target.style.backgroundColor = "var(--orange)";
    clicked.target.style.borderColor = "var(--orange)";
  }
  return (
    <>
      <HelmetProvider>
        <Helmet>
          <title>ZeroTech Trace Advanced Spotting Scopes</title>
          <meta
            name="description"
            content="Zerotech Resources - The one-stop shop for all ZeroTech Trace Advanced Spotting Scope resources."
          />
          <meta
            name="keywords"
            content="ZeroTech, trace advanced, spotting scopes, resources, manuals"
          />
        </Helmet>
      </HelmetProvider>
      <Boxed
        children={
          <>
            <BackBtn />
            <h1>Trace Advanced Spotting Scopes</h1>
            <p>
              Crucial to long range target acquisition and precision fire, the
              ZeroTech Trace Advanced 20-60×80 spotting scope is an essential
              tool for improving your long-range shooting capabilities. The new
              glass etched OSR MRAD reticle is used by the observer to call
              accurate shot adjustments along with identifying and ranging
              potential targets. The OSR reticle is offset from the center of
              the field of view to maxamise the observation area and indexed in
              0.2 MRAD to allow fast and precise shot corrections. ZeroTech has
              also included 0.1 MRAD measuring bars on the elevation and windage
              axes for precise range estimation of known size targets.
            </p>
            <p>
              The use of spotting scopes is important for many reasons, and not
              just limited to long range target shooters. The new Trace ADV
              spotter allows hunters shooting in challenging terrains to get a
              better view of their target animal, which improves accuracy and
              precision when hunting game from long distances. They also allow
              hunters to identify new potential targets when there is limited
              visibility or cover in the environment that would otherwise
              obscure them from view without magnification.
            </p>
          </>
        }
      ></Boxed>
      <section className="productTabs condensed">
        {traceAdvancedSpottingScopes.map((productList) => (
          <div key={productList.title} className="singleProduct">
            <NavLink
              to={productList.url}
              onClick={(e) => {
                selectProduct(e, productList);

                resetButton(e);
              }}
            >
              {productList.title}
            </NavLink>
          </div>
        ))}
      </section>
      <ProductContent product={product} Layout="spotting-scopes" />
    </>
  );
};

export default TraceAdvanced;
