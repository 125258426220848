import React from "react";
import Data from "../../../../data/resources.json";
import { NavLink, useParams } from "react-router-dom";
import BackBtn from "../../../BackBtn";
import { useEffect } from "react";
import Boxed from "../../../../pages/Boxed";
import ProductContent from "../../ProductContent";
import { Helmet, HelmetProvider } from "react-helmet-async";

const TraceRedDot = ({ product, setProduct, selectProduct }) => {
    let categories = Data.products;
    let ThriveRedDot = categories
        .filter((thiscat) => thiscat.category === "trace")
        .filter(
            (thisCatScopes) => thisCatScopes.type === "red dot"
        );

    const location = useParams();

    useEffect(() => {
        window.scrollTo(0, 0);
        const currentUrl = Object.values(location);
        if (currentUrl[0] !== "") {
            let thisUrl = ThriveRedDot.filter((currentCat) =>
                currentCat.url.includes(currentUrl[0])
            );
            setProduct(thisUrl[0]);
        } else {
            let firstProduct = ThriveRedDot[0];
            setProduct(firstProduct);
        }
    });

    function resetButton(clicked) {
        let resetAll = document.querySelectorAll(".singleProduct a");

        for (let i = 0; i < resetAll.length; i++) {
            resetAll[i].style.backgroundColor = "black";
        }
        clicked.target.style.backgroundColor = "var(--orange)";
        clicked.target.style.borderColor = "var(--orange)";
    }
    return (
        <>
            <HelmetProvider>
                <Helmet>
                    <title>ZeroTech Red Dot Trace Red Dot</title>
                    <meta
                        name="description"
                        content="Zerotech Resources - The one-stop shop for all ZeroTech Thrive Red Dot resources."
                    />
                    <meta
                        name="keywords"
                        content="ZeroTech, trace, reddots, resources, manuals, reticles, range chart,"
                    />
                </Helmet>
            </HelmetProvider>
            <Boxed
                children={
                    <>
                        <BackBtn />
                        <h1>Trace Red Dot</h1>
                        <p>The one-stop shop for all your Trace red dot resources.</p>
                        <p>
                            TRACE optics deliver everything you need and nothing you don’t, to execute the most precise and technical shooting results in the most challenging conditions.
                        </p>
                    </>
                }
            ></Boxed>

            <section className="productTabs">
                {ThriveRedDot.map((productList) => (
                    <div key={productList.title} className="singleProduct">
                        <NavLink
                            to={productList.url}
                            onClick={(e) => {
                                selectProduct(e, productList);

                                resetButton(e);
                            }}
                        >
                            {productList.title}
                        </NavLink>
                    </div>
                ))}
            </section>
            <ProductContent product={product} Layout="red dot" />
        </>
    );
};

export default TraceRedDot;
