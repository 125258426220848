import React from "react";
import { NavLink } from "react-router-dom";

const BackBtn = () => {
  return (
    <button>
      <NavLink to="/">BACK</NavLink>
    </button>
  );
};

export default BackBtn;
