import React from "react";
import Data from "../../../../data/resources.json";
import { NavLink, useParams } from "react-router-dom";
import BackBtn from "../../../../Components/BackBtn";
import Boxed from "../../../../pages/Boxed";
import { useEffect } from "react";
import ProductContent from "../../ProductContent";
import { Helmet, HelmetProvider } from "react-helmet-async";

const Vengeance = ({ product, setProduct, selectProduct }) => {
  let categories = Data.products;
  let vengeanceRiflescope = categories.filter(
    (thiscat) => thiscat.category === "vengeance"
  );

  const location = useParams();

  useEffect(() => {
    window.scrollTo(0, 0);
    const currentUrl = Object.values(location);
    if (currentUrl[0] !== "") {
      let thisUrl = vengeanceRiflescope.filter((currentCat) =>
        currentCat.url.includes(currentUrl[0])
      );
      setProduct(thisUrl[0]);
    } else {
      let firstProduct = vengeanceRiflescope[0];
      setProduct(firstProduct);
    }
  });

  function resetButton(clicked) {
    let resetAll = document.querySelectorAll(".singleProduct a");

    for (let i = 0; i < resetAll.length; i++) {
      resetAll[i].style.backgroundColor = "black";
    }
    clicked.target.style.backgroundColor = "var(--orange)";
    clicked.target.style.borderColor = "var(--orange)";
  }

  return (
    <>
      <HelmetProvider>
        <Helmet>
          <title>ZeroTech Vengeance Riflescopes</title>
          <meta
            name="description"
            content="Zerotech Resources - The one-stop shop for all ZeroTech Vengeance Riflescope resources."
          />
          <meta
            name="keywords"
            content="ZeroTech, vengeance, riflescopes, resources, manuals, reticles, range chart,"
          />
        </Helmet>
      </HelmetProvider>
      <Boxed
        children={
          <>
            <BackBtn />
            <h1>Vengeance Riflescopes</h1>
            <p>
              The one-stop shop for all your Vengeance riflescope resources.
            </p>
            <p>
              VENGEANCE optics are engineered, tested and priced to deliver you
              the absolute best value for money without short-changing you on
              features.
            </p>
          </>
        }
      ></Boxed>
      <section className="productTabs">
        {vengeanceRiflescope.map((productList) => (
          <div key={productList.title} className="singleProduct">
            <NavLink
              to={productList.url}
              onClick={(e) => {
                selectProduct(e, productList);

                resetButton(e);
              }}
            >
              {productList.title}
            </NavLink>
          </div>
        ))}
      </section>
      <ProductContent product={product} Layout="riflescope" />
    </>
  );
};

export default Vengeance;
