import React from "react";

const CloseBtn = ({ close }) => {
  const handleClick = () => {
    document.getElementById(close).classList.remove("open");
  };

  return (
    <div className="closeBtn" onClick={handleClick}>
      CLOSE
    </div>
  );
};

export default CloseBtn;
