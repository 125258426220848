import Modal from "../Modal";

const DimensionsImg = ({ product, dimensionsOpen, setDimensionsOpen }) => {
  return (
    <>
      <h3>Dimensions</h3>

      {product ? (
        <>
          <img
            src={product}
            alt="product Dimensions"
            onClick={() => setDimensionsOpen(true)}
          />

          <Modal open={dimensionsOpen} onClose={() => setDimensionsOpen(false)}>
            <img src={product} alt="Large Dimension Graph" />
          </Modal>
        </>
      ) : (
        "No Image Available"
      )}
    </>
  );
};

export default DimensionsImg;
