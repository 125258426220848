import React from "react";
import ReactDOM from "react-dom";
const Modal = ({ open, onClose, children }) => {
  if (!open) return null;
  return ReactDOM.createPortal(
    <div className="modalOverlay">
      <div className="modalImg">
        <button onClick={onClose} className="closeBtn">
          CLOSE
        </button>
        {children}
      </div>
    </div>,
    document.getElementById("portal")
  );
};

export default Modal;
