import "./styles.css";
import Header from "./Components/header/Header";
import Footer from "./Components/Footer";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import { useState } from "react";
import Home from "./pages/Home";
import NotFound from "./pages/NotFound";
import Vengeance from "./Components/productContent/riflescopes/riflescopeCategories/Vengeance";
import ThriveScope from "./Components/productContent/riflescopes/riflescopeCategories/Thrive";
import ThriveHdScope from "./Components/productContent/riflescopes//riflescopeCategories/ThriveHD";
import Trace from "./Components/productContent/riflescopes/riflescopeCategories/Trace";
import TraceAdvanced from "./Components/productContent/riflescopes/riflescopeCategories/TraceAdvanced";

import ThriveRedDot from "./Components/productContent/redDots/redDotCategories/ThriveRedDot";
import ThriveHDRedDot from "./Components/productContent/redDots/redDotCategories/ThriveHDRedDot";
import TraceRedDot from "./Components/productContent/redDots/redDotCategories/TraceRedDot";

import Binoculars from "./pages/Binoculars";
import ThriveBino from "./Components/productContent/binoculars/binoCategories/ThriveBino";
import ThriveHdBino from "./Components/productContent/binoculars/binoCategories/ThriveHdBino";

import SpottingScopes from "./Components/productContent/spottingScopes/SpottingCategories/TraceAdvanced";
import Sunshade from "./Components/productContent/accessories/accCategories/Sunshade";

import Popup from "./Components/Popup";
import Contact from "./pages/Contact";
import { Helmet, HelmetProvider } from "react-helmet-async";
import Video from "./pages/Video";
function App() {
  const [product, setProduct] = useState({
    category: "",
    title: "",
    reticle: "",
    dimensionsUrl: "",
    url: "",
    sku: "",
    apn: "",
    ausUrl: "",
    usUrl: "",
    ukUrl: "",
  });

  const selectProduct = (e, productList) => {
    setProduct(productList);
  };

  return (
    <>
      <HelmetProvider>
        <Helmet>
          <title>ZeroTech Resources</title>
          <meta
            name="description"
            content="Zerotech Resources - The one-stop shop for all your ZeroTech optic resources."
          />
          <meta
            name="keywords"
            content="ZeroTech, riflescopes, binoculars, manuals, reticles, range chart, dimensions, barcode number, thrive, thrive hd, vengeance, trace, trace advanced"
          />
        </Helmet>
      </HelmetProvider>
      <Router basename={process.env.PUBLIC_URL}>
        <Header />
        <Popup product={product} />
        <div className="mainContent">
          <Routes>
            <Route path="/" element={<Home />} />
            <Route
              path="/riflescopes/"
              element={<Navigate to="/riflescopes/vengeance/" />}
            />
            <Route
              path="/riflescopes/vengeance/*"
              element={
                <Vengeance
                  setProduct={setProduct}
                  selectProduct={selectProduct}
                  product={product}
                />
              }
            />
            <Route
              path="/riflescopes/thrive/*"
              element={
                <ThriveScope
                  setProduct={setProduct}
                  selectProduct={selectProduct}
                  product={product}
                />
              }
            />
            <Route
              path="/riflescopes/thrive-hd/*"
              element={
                <ThriveHdScope
                  setProduct={setProduct}
                  selectProduct={selectProduct}
                  product={product}
                />
              }
            />
            <Route
              path="/riflescopes/trace/*"
              element={
                <Trace
                  setProduct={setProduct}
                  selectProduct={selectProduct}
                  product={product}
                />
              }
            />
            <Route
              path="/riflescopes/trace-advanced/*"
              element={
                <TraceAdvanced
                  setProduct={setProduct}
                  selectProduct={selectProduct}
                  product={product}
                />
              }
            />

            <Route
              path="/reddots/thrive/*"
              element={
                <ThriveRedDot
                  setProduct={setProduct}
                  selectProduct={selectProduct}
                  product={product}
                />
              }
            />
            <Route
              path="/reddots/thrive-hd/*"
              element={
                <ThriveHDRedDot
                  setProduct={setProduct}
                  selectProduct={selectProduct}
                  product={product}
                />
              }
            />
            <Route
              path="/reddots/trace/*"
              element={
                <TraceRedDot
                  setProduct={setProduct}
                  selectProduct={selectProduct}
                  product={product}
                />
              }
            />

            <Route
              path="/binoculars/"
              element={
                <Binoculars
                  setProduct={setProduct}
                  selectProduct={selectProduct}
                  product={product}
                />
              }
            />
            <Route
              path="/binoculars/thrive/*"
              element={
                <ThriveBino
                  setProduct={setProduct}
                  selectProduct={selectProduct}
                  product={product}
                />
              }
            />
            <Route
              path="/binoculars/thrive-hd/*"
              element={
                <ThriveHdBino
                  setProduct={setProduct}
                  selectProduct={selectProduct}
                  product={product}
                />
              }
            />

            <Route
              path="/spotting-scopes/"
              element={<Navigate to="/spotting-scopes/trace-advanced/" />}
            />
            <Route
              path="/spotting-scopes/trace-advanced/*"
              element={
                <SpottingScopes
                  setProduct={setProduct}
                  selectProduct={selectProduct}
                  product={product}
                />
              }
            />

            <Route
              path="/accessories/sunshade/*"
              element={
                <Sunshade
                  setProduct={setProduct}
                  selectProduct={selectProduct}
                  product={product}
                />
              }
            />

            <Route path="/contact" element={<Contact />} />
            <Route path="/videos" element={<Video />} />
            <Route path="*" element={<NotFound />} />
          </Routes>
        </div>
        <Footer />
      </Router>
    </>
  );
}

export default App;
