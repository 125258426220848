import React from "react";

const ProductDownloads = ({ product, type }) => {
  let reticleManual = "";
  let reticleRangeChart = "";

  if (product.type === "riflescope" || product.type === "spotting-scope") {
    switch (product.reticle) {
      case "rar-illum":
        reticleManual = "/images/reticleManuals/rar.pdf";
        reticleRangeChart = "/images/rangeCharts/rar.pdf";
        break;
      case "phr":
        reticleManual = "/images/reticleManuals/phr-specs.pdf";
        reticleRangeChart = "/images/rangeCharts/phr-rangechart.pdf";
        break;
      case "phrii":
        reticleManual = "/images/reticleManuals/phrii-specs.pdf";
        reticleRangeChart = "/images/rangeCharts/phrii-rangechart.pdf";
        break;
      case "phrii-illum":
        reticleManual = "/images/reticleManuals/phrii-specs.pdf";
        reticleRangeChart = "/images/rangeCharts/phrii-illum-rangechart.pdf";
        break;
      case "phr3":
        reticleManual = "/images/reticleManuals/phr3-specs.pdf";
        reticleRangeChart = "/images/rangeCharts/phr3-rangechart.pdf";
        break;
      case "r3":
        reticleManual = "/images/reticleManuals/r3-specs.pdf";
        reticleRangeChart = "/images/rangeCharts/r3-rangechart.pdf";
        break;
      case "r3-illum":
        reticleManual = "/images/reticleManuals/rar.pdf";
        reticleRangeChart = "/images/rangeCharts/rar.pdf";
        break;
      case "mildot":
        reticleManual = "/images/reticleManuals/mildot-specs.pdf";
        reticleRangeChart = "/images/rangeCharts/mildot-rangechart.pdf";
        break;
      case "hunter-illum":
        reticleManual = "/images/reticleManuals/hunter-specs.pdf";
        reticleRangeChart = "/images/rangeCharts/hunter-illum-rangechart.pdf";
        break;
      case "rmg":
        reticleManual = "/images/reticleManuals/rmg-specs.pdf";
        reticleRangeChart = "/images/rangeCharts/rmg-rangechart.pdf";
        break;
      case "rmg2-illum":
        reticleManual = "/images/reticleManuals/rmg2-illum-specs.pdf";
        reticleRangeChart = "/images/rangeCharts/rmg2-illum-rangechart.pdf";
        break;
      case "rmg-moa":
        reticleManual = "/images/reticleManuals/rmg-moa-specs.pdf";
        reticleRangeChart = "/images/rangeCharts/rmg-rangechart.pdf";
        break;
      case "tremor3":
        reticleManual = "/images/reticleManuals/tremor3-specs.pdf";
        reticleRangeChart = "/images/rangeCharts/rmg-rangechart.pdf";
        break;
      case "osr":
        reticleManual = "/images/reticleManuals/osr-reticle-manual.pdf";

        break;
      default:
        reticleManual = "";
        reticleRangeChart = "";
    }
  }

  return (
    <>
      <h3>Downloads</h3>
      <section className="downloadsContainer">
        <div className="buttonList">
          <article>
            <a href={product.manual} target="_blank" rel="noreferrer">
              Product Manual
            </a>
          </article>
          {reticleManual !== "" ? (
            <>
              <article>
                <a href={reticleManual} target="_blank" rel="noreferrer">
                  Reticle Manual
                </a>
              </article>
            </>
          ) : (
            ""
          )}
          {reticleRangeChart !== "" ? (
            <article>
              <a href={reticleRangeChart} target="_blank" rel="noreferrer">
                Range Chart
              </a>
            </article>
          ) : (
            ""
          )}
        </div>
        <article className="productSku">
          <p>SKU: {product.sku}</p>
          <p>APN: {product.apn}</p>
        </article>
      </section>
    </>
  );
};

export default ProductDownloads;
