import React from "react";

import RiflescopeContent from "./riflescopes/RiflescopeContent";
import RedDotContent from "./redDots/RedDotContent";
import BinocularContent from "./binoculars/BinocularContent";
import SpottingScopeContent from "./spottingScopes/SpottingScopeContent";
import AccessoriesContent from "./accessories/AccessoriesContent";

const ProductContent = ({ product, Layout }) => {
  const openPopup = () => {
    document.getElementById("popup").classList.add("open");
  };

  if (Layout === "riflescope") {
    return <RiflescopeContent product={product} openPopup={openPopup} />;
  }

  if (Layout === "red dot") {
    return <RedDotContent product={product} openPopup={openPopup} />;
  }

  if (Layout === "binocular") {
    return <BinocularContent product={product} openPopup={openPopup} />;
  }

  if (Layout === "spotting-scopes") {
    return <SpottingScopeContent product={product} openPopup={openPopup} />;
  }

  if (Layout === "accessories") {
    return <AccessoriesContent product={product} openPopup={openPopup} />;
  }
};

export default ProductContent;
